import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';

const CountryRegionDropdown = () => {
  const [countries, setCountries] = useState([]);
  const [plans, setPlans] = useState([]);
  const [planDetails, setPlanDetails] = useState([]);
  const [flag, setFlag] = useState(4);
  const [selectedCountryRegion, setSelectedCountryRegion] = useState('');
  const [selectedCountryId, setSelectedCountryId] = useState('');
  const [selectedPlanId, setSelectedPlanId] = useState('');
  const [token, setToken] = useState('');

  const [emails, setEmails] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState(null);

  useEffect(() => {
    fetchEmailData();
  }, []);

  const fetchEmailData = async () => {
    const token = sessionStorage.getItem('token');

    const apiUrl = 'https://i47v6yw3lg6zz3tvjpzvlhwe2a0xxaef.lambda-url.ap-south-1.on.aws/api/Billing/GetOfflineCustomers';
  
    try {
      const response = await axios.post(apiUrl, {
        flag: '2',
        country: ''
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      const jsonData = response.data.data[0].jsonData;
      const parsedData = JSON.parse(jsonData);
      const emails = parsedData.Table.map(entry => ({
        value: entry.Customerid,
        label: entry.Email
      }));
      setEmails(emails);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleEmailChange = selectedOption => {
    setSelectedEmail(selectedOption);
    console.log('Selected ID:', selectedOption.value); // Logging selected ID
  };

  useEffect(() => {
    const sessionToken = sessionStorage.getItem('token');
    if (sessionToken) {
      setToken(sessionToken);
      fetchData(flag, sessionToken);
    }
  }, [flag]);

  const fetchData = async (flag, token, country = null, planid = null) => {
    const apiURL = flag === 0 ? 'https://i47v6yw3lg6zz3tvjpzvlhwe2a0xxaef.lambda-url.ap-south-1.on.aws/api/Billing/GetOfflinePlansbyID' : 'https://i47v6yw3lg6zz3tvjpzvlhwe2a0xxaef.lambda-url.ap-south-1.on.aws/api/Billing/GetOfflineCustomers';
    const requestData = flag === 0 ? { flag, planid } : { flag, country };

    try {
      const response = await axios.post(apiURL, requestData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.isSuccess) {
        const data = JSON.parse(response.data.data[0].jsonData).Table;
        if (flag === 3) {
          setPlans(data);
        } else if (flag === 0) {
          setPlanDetails(data);
        } else {
          setCountries(data);
        }
      } else {
        console.error('Failed to fetch data');
      }
    } catch (error) {
      console.error('Error fetching data', error);
    }
  };

  const handleCountryRegionSelection = (e) => {
    const value = e.target.value;
    setSelectedCountryRegion(value);
    const newFlag = value === 'country' ? 4 : 1;
    setFlag(newFlag);
    fetchData(newFlag, token);
  };

  const handleCountrySelection = (e) => {
    const countryId = e.target.value;
    setSelectedCountryId(countryId);
    fetchData(3, token, countryId);
  };

  const handlePlanSelection = (e) => {
    const planId = e.target.value;
    setSelectedPlanId(planId);
    fetchData(0, token, null, planId);
  };

  const createOfflinePlan = () => {
    const UserId = sessionStorage.getItem('userID');
    const selectedTVD = document.getElementById('airline').value;

    if (!planDetails.length) {
      alert('Plan details not found.');
      return;
    }

    // Extract buying and selling amounts directly from planDetails
    const buyingAmount = planDetails.map(detail => detail.buyingcost).join(', ');
    const sellingAmount = planDetails.map(detail => detail.sellingcost).join(', ');

    // Prepare payload
    const formatDate = (date) => {
      const d = new Date(date);
      const month = ('0' + (d.getMonth() + 1)).slice(-2);
      const day = ('0' + d.getDate()).slice(-2);
      const year = d.getFullYear();
      return `${year}-${month}-${day}`; // Assuming the desired format is 'YYYY-MM-DD'
    };

    const todayDate = formatDate(Date.now());
    console.log('Formatted Date:', todayDate);

    const payload = {
      flag: '1',
      id: UserId || '', // Use fallback value if UserID is null
      planid: selectedPlanId,
      source: selectedTVD, // Replace with the selected TVD value
      customerId: selectedEmail ? selectedEmail.label : '', // Extract the email address from selectedEmail
      sellingAmount: sellingAmount || '',
      buyingAmount: buyingAmount || '',
      guid: '',
      travelDateString: todayDate, // Set today's date
      createdBy: UserId
    };
    
    // Alert the payload before sending
    alert('Sending payload:\n' + JSON.stringify(payload, null, 2));

    axios.post('https://localhost:7049/api/Billing/CreateOfflinePlans', payload, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }).then(response => {
      console.log('API response:', response);
      alert('Plan created successfully!');
    }).catch(error => {
      console.error('Error creating offline plan:', error);
      alert('Error creating offline plan. Please try again.');
    });
  };

  return (
    <div className="container mx-auto p-4">
      <div className="mb-4">
        <label htmlFor="selection" className="block text-gray-700">
          Select Existing Customer:
        </label>
        <Select
          value={selectedEmail}
          onChange={handleEmailChange}
          options={emails}
          placeholder="Select an email..."
        />
      </div>
      <div className="mb-4">
        <label htmlFor="airline" className="block text-gray-700">
          Select TVD:
        </label>
        <select
          id="airline"
          name="airline"
          className="block w-full mt-1 p-2 border border-gray-300 rounded"
        >
          <option value="">Select Source</option>
          <option value="TVLKA">TVLKA</option>
          <option value="KKD">KKD</option>
          <option value="AMZUK">AMZUK</option>
          <option value="AMZUS">AMZUS</option>
          <option value="RKTN">RKTN</option>
          <option value="NVR">NVR</option>
          <option value="TKT">TKT</option>
          <option value="WebMis">WEB Missing</option>
          <option value="E_BY">E_BY</option>
          <option value="AIRHUB UK">AIRHUB UK</option>
          <option value="TEST">TEST</option>
        </select>
      </div>
      <div className="mb-4">
        <label htmlFor="selection" className="block text-gray-700">
          Select Country/Region:
        </label>
        <select
          id="selection"
          className="block w-full mt-1 p-2 border border-gray-300 rounded"
          value={selectedCountryRegion}
          onChange={handleCountryRegionSelection}
        >
          <option value="">Select Plan Type</option>
          <option value="country">Region</option>
          <option value="region">Country</option>
        </select>
      </div>
      {selectedCountryRegion && (
        <div className="mb-4">
          <label htmlFor="country-region" className="block text-gray-700">
            Country/Region:
          </label>
          <select
            id="country-region"
            className="block w-full mt-1 p-2 border border-gray-300 rounded"
            value={selectedCountryId}
            onChange={handleCountrySelection}
          >
            <option value="">Select Country/Region</option>
            {countries.map((country) => (
              <option key={country.ID} value={country.ID}>
                {country.CountryName}
              </option>
            ))}
          </select>
        </div>
      )}
      {selectedCountryId && (
        <div className="mb-4">
          <label htmlFor="plans" className="block text-gray-700">
            Plans:
          </label>
          <select
            id="plans"
            className="block w-full mt-1 p-2 border border-gray-300 rounded"
            value={selectedPlanId}
            onChange={handlePlanSelection}
          >
            <option value="">Select Plan</option>
            {plans.map((plan) => (
              <option key={plan.P_id} value={plan.P_id}>
                {plan.planename}
              </option>
            ))}
          </select>
        </div>
      )}
      {planDetails.length > 0 && (
        <table className="min-w-full bg-white mt-4">
          <thead>
            <tr>
              <th className="py-2">Inventory ID</th>
              <th className="py-2">Activation Code</th>
              <th className="py-2">Plan Name</th>
              <th className="py-2">Buying cost</th>
              <th className="py-2">Selling cost</th>
              <th className="py-2">Data Allowance</th>
              <th className="py-2">Validity</th>
              <th className="py-2">Action</th>
            </tr>
          </thead>
          <tbody>
            {planDetails.map((detail) => (
              <tr key={detail.inventry_id}>
                <td className="border px-4 py-2">{detail.inventry_id}</td>
                <td className="border px-4 py-2">{detail.activation_code}</td>
                <td className="border px-4 py-2">{detail.planename}</td>
                <td className="border px-4 py-2">{detail.buyingcost} $</td>
                <td className="border px-4 py-2">{detail.sellingcost} $</td>
                <td className="border px-4 py-2">{detail.dataallowance} {detail.dataallowancetype}</td>
                <td className="border px-4 py-2">{detail.vaildity} Days</td>
                <td className="border px-4 py-2 text-center">
                  <div className="self-stretch shadow-[0px_2px_8px_rgba(92,_152,_140,_0.3)] rounded-xl bg-brand-green-selected bg-[#FB9678] text-white border-blue-500 h-10 flex flex-row items-center justify-center p-2.5 box-border text-brand-white m-4">
                    <button onClick={createOfflinePlan}>
                      Create Plan
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default CountryRegionDropdown;
