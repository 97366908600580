import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { FiSettings } from 'react-icons/fi';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

import { Navbar, Footer, Sidebar, ThemeSettings } from './components';
import { Dashboard, Calendar, Employees, Stacked, Pyramid, Customers, Kanban, Line, Area, Bar, Pie, Financial, ColorPicker, ColorMapping, Editor } from './pages';
import './App.css';

import { useStateContext } from './contexts/ContextProvider';
import Login from './components/Login';
import Bindregion from './components/Bindregion';
import RegisterUser from './pages/RegisterUser';
import BindPlanName from './components/BindPlanName';
import UploadInventory from './components/UploadInventory';
import InventoryQT from './components/InventoryQT';
import CreateCustomer from './components/CreateCustomer';
import CreateOfflinePlans from './components/CreateOfflinePlans';
import GetAllUsers from './components/GetAllUsers';
import Plans from './components/Plans';

const App = () => {
  const { setCurrentColor, setCurrentMode, currentMode, activeMenu, currentColor, themeSettings, setThemeSettings } = useStateContext();
  const location = useLocation();

  useEffect(() => {
    const currentThemeColor = localStorage.getItem('colorMode');
    const currentThemeMode = localStorage.getItem('themeMode');
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
  }, []);

  const showContent = location.pathname !== '/' && location.pathname !== '/login';

  return (
    <div className={currentMode === 'Dark' ? 'dark' : ''}>
      <div className="flex relative dark:bg-main-dark-bg">
        {showContent && (
          <div className="fixed right-4 bottom-4" style={{ zIndex: '1000' }}>
            <TooltipComponent content="Settings" position="Top">
              {/* <button
                type="button"
                onClick={() => setThemeSettings(true)}
                style={{ background: currentColor, borderRadius: '50%' }}
                className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray">
                <FiSettings />
              </button> */}
            </TooltipComponent>
          </div>
        )}
        {showContent && activeMenu ? (
          <div className="w-60 fixed sidebar dark:bg-secondary-dark-bg bg-white ">
            <Sidebar />
          </div>
        ) : (
          showContent && (
            <div className="w-0 dark:bg-secondary-dark-bg">
              <Sidebar />
            </div>
          )
        )}
        <div
          className={
            showContent && activeMenu
              ? 'dark:bg-main-dark-bg  bg-main-bg min-h-screen md:ml-64 w-full  '
              : 'bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 '
          }
        >
          {showContent && (
            <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
              <Navbar />
            </div>
          )}
          <div>
            {themeSettings && <ThemeSettings />}

            <Routes>
              {/* login  */}
              <Route path="/" element={<Login />} />
              <Route path="/login" element={<Login />} />
              {/* <Route path="/Login2" element={<Login2 />} /> */}
              
              {/* Inventory  */}
              <Route path='/QuickTool' element={<InventoryQT/>} />
              <Route path="UploadInventory" element={<UploadInventory />}/>

              {/* dashboard  */}
              <Route path='/regUser' element={<RegisterUser/>} />
              {/* <Route path="/ecommerce" element={<Ecommerce />} /> */}
              <Route path="/Dashboard" element={<Dashboard />} />

              {/* Billing */}
              <Route path='/CreateCustomer' element={<CreateCustomer/>} />
              <Route path='/CreateOfflinePlans' element={<CreateOfflinePlans/>} />
              
              {/* pages  */}
              <Route path="/employees" element={<Employees />} />
              <Route path="/customers" element={<Customers />} />

              {/* apps  */}
              <Route path="/kanban" element={<Kanban />} />
              <Route path="/editor" element={<Editor />} />
              <Route path="/calendar" element={<Calendar />} />
              <Route path="/Bindregion" element={<Bindregion />} />
              <Route path="/BindPlanName" element={<BindPlanName />} />
              <Route path="/GetAllUsers" element={<GetAllUsers />} />

              <Route path="/Plans" element={<Plans />} />


              <Route path="/color-picker" element={<ColorPicker />} />

              {/* charts  */}
              <Route path="/line" element={<Line />} />
              <Route path="/area" element={<Area />} />
              <Route path="/bar" element={<Bar />} />
              <Route path="/pie" element={<Pie />} />
              <Route path="/financial" element={<Financial />} />
              <Route path="/color-mapping" element={<ColorMapping />} />
              <Route path="/pyramid" element={<Pyramid />} />
              <Route path="/stacked" element={<Stacked />} />
            </Routes>
          </div>
          {showContent && <Footer />}
        </div>
      </div>
    </div>
  );
};

const MainApp = () => (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

export default MainApp;
