import React, { useState, useEffect, useRef } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Import eye icons
import { useNavigate } from 'react-router-dom';
import Airhub from '../assests/Airhub_logo.png';
import sideimg from '../assests/Side_img.jpg';
function Login() {
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [otp, setOtp] = useState('');
    const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
    const [message, setMessage] = useState('');
    const [loginStep, setLoginStep] = useState(1); // Step 1: login, Step 2: OTP verification
    const [loading, setLoading] = useState(false); // State for loading indicator
    const navigate = useNavigate();
    const timerRef = useRef(null);

    const handleUserNameChange = (e) => {
        setUserName(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleOtpChange = (e) => {
        setOtp(e.target.value);
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const resetTimer = () => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }

        timerRef.current = setTimeout(() => {
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('username');
            sessionStorage.removeItem('role');
            sessionStorage.removeItem('userID');

            console.log("Session expired, user logged out");
            alert("Timeout");
            navigate("/");
        }, 1800000); // 30 minutes
    };

    const handleSubmit = async () => {
        setLoading(true); // Start loading
        try {
            const response = await fetch('https://i47v6yw3lg6zz3tvjpzvlhwe2a0xxaef.lambda-url.ap-south-1.on.aws/api/User/UserLogin', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ userName, password })
            });
            const data = await response.json();

            if (response.ok) {
                console.log(data.data.userID);
                console.log(data.data.userName);
                console.log(data.data.role);
                console.log('Successfully login welcome');

                sessionStorage.setItem('token', '');

                // Store user credentials in sessionStorage
                sessionStorage.setItem('twoFA', data.data.twoFA);
                sessionStorage.setItem('token', data.token);
                sessionStorage.setItem('userID', data.data.userID);
                sessionStorage.setItem('role', data.data.role);
                sessionStorage.setItem('partnerCode', data.data.partnerCode);
                sessionStorage.setItem('username', data.data.userName);
                sessionStorage.setItem('email', data.data.email);
                sessionStorage.setItem('moduleIds', data.data.moduleIds);

                // Store subModuleIds and permissionStatuses if available
                if (data.data.moduleIds) {
                    sessionStorage.setItem('moduleIds', JSON.stringify(data.data.moduleIds));
                }
                if (data.data.subModuleIds) {
                    sessionStorage.setItem('subModuleIds', JSON.stringify(data.data.subModuleIds));
                }
                if (data.data.permissionStatuses) {
                    sessionStorage.setItem('permissionStatuses', JSON.stringify(data.data.permissionStatuses));
                }

                if (data.data.twoFA === true) {
                    setLoginStep(2);
                } else {
                    if (data.token != null) {
                        resetTimer();
                        navigate('/Dashboard');
                    }
                }
            } else {
                setMessage('Login Failed.');
            }
        } catch (error) {
            console.log('error', error);
            setMessage('Email or password Incorrect');
        } finally {
            setLoading(false); // Stop loading
        }
    };

    const handleOtpVerification = async () => {
        setLoading(true); // Start loading
        try {
            const response = await fetch('https://i47v6yw3lg6zz3tvjpzvlhwe2a0xxaef.lambda-url.ap-south-1.on.aws/api/User/VerifyOtpAMian', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ userID: sessionStorage.getItem('userID'), username: sessionStorage.getItem('username'), role: sessionStorage.getItem('role'), otp })
            });
            const data = await response.json();

            if (response.ok) {
                console.log('OTP verified successfully');
                sessionStorage.setItem('token', data.token);
                resetTimer();
                navigate('/Bindregion');
            } else {
                setMessage('OTP Verification Failed.');
            }
        } catch (error) {
            console.log('error', error);
            setMessage('OTP Verification Failed.');
        } finally {
            setLoading(false); // Stop loading
        }
    };

    useEffect(() => {
        window.addEventListener('mousemove', resetTimer);
        window.addEventListener('keypress', resetTimer);

        resetTimer(); // Start the initial timer when the component mounts

        return () => {
            clearTimeout(timerRef.current);
            window.removeEventListener('mousemove', resetTimer);
            window.removeEventListener('keypress', resetTimer);
        };
    }, []);

    return (
        <div className="flex justify-center items-center h-screen bg-[#f9e6e0]">
            <div className="flex w-11/12 bg-white shadow-lg rounded-lg overflow-hidden h-5/6">
                <div className="flex flex-1 flex-col justify-center items-center p-10 bg-[#f9fafa]">
                    <div className="w-full max-w-md">
                        <div className="flex justify-center mb-5">
                            <img src={Airhub} alt="ScannerGo" className="w-20" />
                        </div>
                        <div className="mb-4">
                            <input style={{ backgroundColor: "#e9f8f9" }} type="email" placeholder="Email Address" name="email" required className="w-full p-4 border-2 border-black rounded-lg bg-e9f8f9" onChange={handleUserNameChange} />
                        </div>
                        <div className="relative">
                            <label htmlFor="Password" className="block">Password</label>
                            <input style={{ backgroundColor: "#e9f8f9" }} type={showPassword ? "text" : "password"} placeholder="Enter your Password" name="password" required className="w-full p-4 border-2 border-black rounded-lg bg-e9f8f9" onChange={handlePasswordChange} />
                            <span className="mt-2 absolute top-1/2 right-4 transform -translate-y-1/2 cursor-pointer" onClick={togglePasswordVisibility}>
                                {showPassword ? <FaEyeSlash size={20} /> : <FaEye size={20} />}
                            </span>
                        </div>
                        <br />
                        {loginStep === 2 && (
                            <>
                                <label htmlFor="otp" className="block">OTP</label>
                                <input style={{ backgroundColor: "#e9f8f9" }} type="text" placeholder="Enter your OTP" name="otp" required className="w-full p-4 border-2 border-black rounded-lg bg-e9f8f9" onChange={handleOtpChange} />
                                <br />
                                <br />
                            </>
                        )}
                        <div className="flex justify-between items-center mb-4">
                            <label className="flex items-center">
                                <input type="checkbox" className="mr-2" /> Remember me
                            </label>
                            <p className="text-grey">Forgot password?</p>
                        </div>
                        <p style={{ fontSize: '1.5em', color: 'red' }}>{message}</p>
                        <div className="mt-8 flex items-center justify-center">
                            <button type="submit" className="w-full p-3 bg-gray-800 text-white text-lg rounded-lg" onClick={loginStep === 1 ? handleSubmit : handleOtpVerification} disabled={loading}>
                                {loading ? 'Loading...' : loginStep === 1 ? 'Login' : 'Verify OTP'}
                            </button>
                        </div>
                    </div>
                </div>
                <div
                    className="flex flex-1 flex-col justify-center items-center bg-center bg-cover bg-no-repeat text-white"
                    style={{ backgroundImage: `url(${sideimg})` }}
                />
            </div>
        </div>
    );
}

export default Login;
