import React, { useState, useEffect, useId } from 'react';

const UploadInventory = () => {
  const [plans, setPlans] = useState([]);
  const [selectedPlanId, setSelectedPlanId] = useState(null);
  const [planDetails, setPlanDetails] = useState({
    buyingcost: '',
    Currency: '',
    contryname: '',
    regionname: '',
    country: '',
    region: '',
    supplier: ''
  });
  const [mode, setMode] = useState('ROAMING'); // State to track the selected mode
  const [uploadType, setUploadType] = useState('SINGLE'); // State to track upload type (SINGLE or BULK)

  // Get token and userID from session storage
  const token = sessionStorage.getItem('token');
  const userID = sessionStorage.getItem('userID');

  // Fetch plans when the component mounts
  useEffect(() => {
    if (token ) {
      fetch('https://i47v6yw3lg6zz3tvjpzvlhwe2a0xxaef.lambda-url.ap-south-1.on.aws/api/Plan/GetPlanDetailsbyType', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
          body: JSON.stringify({
            flag: 1,
            roleid: "3",
            planType: "Local"
          })
          })
      .then(response => response.json())
      .then(data => {
        if (data.isSuccess) {
          const plans = JSON.parse(data.data[0].jsonData).Table;
          setPlans(plans);
        }
      });
    }
  }, [token, userID]);

  // Fetch plan details when a plan is selected
  useEffect(() => {
    if (selectedPlanId && token && userID) {
      fetch('https://i47v6yw3lg6zz3tvjpzvlhwe2a0xxaef.lambda-url.ap-south-1.on.aws/api/Plan/GetPlandetailsbyid', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'userID': userID
        },
        body: JSON.stringify({
          flag: 1,
          roleid: userID,
          planId: selectedPlanId,
          type: ""

        })
      })
      .then(response => response.json())
      .then(data => {
        if (data.isSuccess) {
          const details = JSON.parse(data.data[0].jsonData).Table[0];
          setPlanDetails(details);
        }
      });
    }
  }, [selectedPlanId, token, userID]);

  // Function to handle form submission
   const handleSubmit = (e) => {
    e.preventDefault();

    let simPinValue = e.target.simPin?.value;
    // Check if simPinValue is empty or not a number
    if (!simPinValue || isNaN(simPinValue)) {
      simPinValue = 0; // Set simPinValue to 0 if it's empty or not a number
    }

    if (uploadType === 'SINGLE' && token && userID) {
      const { buyingcost, Currency, country, supplier } = planDetails;
      const formData = {
        flag: 1,
        Id: 0,
        RegionId: '',
        Uid: userID,
        PlanId: selectedPlanId,
        CountryId: country,
        VendorId: supplier,
        ActivationCode: e.target.activationCode.value,
        InCost: buyingcost,
        SimPin: simPinValue, // Use the adjusted simPinValue here
        ICCID: e.target.iccid.value,
        Currency: Currency,
        ServiceDeviceId: e.target.serviceDeviceId.value,
      };

      // Show alert with formData before sending to API
      alert(JSON.stringify(formData, null, 2));

      fetch('https://i47v6yw3lg6zz3tvjpzvlhwe2a0xxaef.lambda-url.ap-south-1.on.aws/api/Inventory/UploadSingleInventory', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'userID': userID
        },
        body: JSON.stringify(formData)
      })
      .then(response => response.json())
      .then(data => {
        if (data.isSuccess) {
          alert('API works successfully!'); // Show success message
        } else {
          alert('API returned an error. Please try again.'); // Show error message
        }
      })
      .catch(error => {
        console.error('Error uploading single inventory:', error);
        alert('Error uploading single inventory. Please try again.'); // Notify user on error
      });
    } else if (uploadType === 'BULK' && token && userID) {
      const formData = new FormData();
      formData.append('flag', 1);
      formData.append('Uid', userID);
      formData.append('PlanId', selectedPlanId);
      formData.append('CountryId', planDetails.country);
      formData.append('VendorId', planDetails.supplier);
      formData.append('File', e.target.chooseFile.files[0]);

      // Show alert with formData keys before sending to API
      alert([...formData.keys()].join(', '));

      fetch('https://i47v6yw3lg6zz3tvjpzvlhwe2a0xxaef.lambda-url.ap-south-1.on.aws/api/Inventory/UploadBulkInventory', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'userID': userID
        },
        body: formData
      })
      .then(response => response.json())
      .then(data => {
        if (data.isSuccess) {
          alert('Bulk inventory uploaded successfully!'); // Show success message
        } else {
          alert('API returned an error. Please try again.'); // Show error message
        }
      })
      .catch(error => {
        console.error('Error uploading bulk inventory:', error);
        alert('Error uploading bulk inventory. Please try again.'); // Notify user on error
      });
    } else {
      console.log('Form Data:', {
        ...planDetails,
        mode,
        uploadType
      });
    }
  };

  
  

  // Function to reset plan details and selected plan
  const resetPlanDetails = () => {
    setPlanDetails({
      buyingcost: '',
      Currency: '',
      contryname: '',
      regionname: '',
      country: '',
      region: '',
      supplier: ''
    });
    setSelectedPlanId(null);
  };

  // Render conditionally based on mode and uploadType
  const renderFields = () => {
    if (uploadType === 'BULK') {
      if (mode === 'ROAMING') {
        return (
          <>
            <div className="flex mb-4">
              <div className="w-1/2 pr-2">
                <label htmlFor="supplier" className="block text-sm font-medium text-gray-700">Supplier Name</label>
                <input
                  type="text"
                  id="supplier"
                  name="supplier"
                  value={planDetails.supplier || ''}
                  readOnly
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-gray-100 rounded-md shadow-sm focus:outline-none sm:text-sm"
                />
              </div>
              
              <div className="w-1/2 pr-2">
                <label htmlFor="buyingcost" className="block text-sm font-medium text-gray-700">Buying Cost</label>
                <input
                  type="text"
                  id="buyingcost"
                  name="buyingcost"
                  value={planDetails.buyingcost || ''}
                  readOnly
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-gray-100 rounded-md shadow-sm focus:outline-none sm:text-sm"
                />
              </div>
            </div>
  
            <div className="flex mb-4">
              <div className="w-1/2 pr-2">
                <label htmlFor="country" className="block text-sm font-medium text-gray-700">Country</label>
                <input
                  type="text"
                  id="country"
                  name="country"
                  value={planDetails.country || ''}
                  readOnly
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-gray-100 rounded-md shadow-sm focus:outline-none sm:text-sm"
                />
              </div>
              <div className="w-1/2 pr-2">          
                <label htmlFor="chooseFile" className="block text-sm font-medium text-gray-700">Choose File</label>
                <input type="file" id="chooseFile" name="chooseFile" className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none sm:text-sm" />
              </div>
            </div>
          </>
        );
      } else if (mode === 'LOCAL') {
        return (
          <>
            <div className="flex mb-4">
              <div className="w-1/2 pr-2">
                <label htmlFor="supplier" className="block text-sm font-medium text-gray-700">Supplier Name</label>
                <input
                  type="text"
                  id="supplier"
                  name="supplier"
                  value={planDetails.supplier || ''}
                  readOnly
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-gray-100 rounded-md shadow-sm focus:outline-none sm:text-sm"
                />
              </div>
              
              <div className="w-1/2 pr-2">
                <label htmlFor="buyingcost" className="block text-sm font-medium text-gray-700">Buying Cost</label>
                <input
                  type="text"
                  id="buyingcost"
                  name="buyingcost"
                  value={planDetails.buyingcost || ''}
                  readOnly
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-gray-100 rounded-md shadow-sm focus:outline-none sm:text-sm"
                />
              </div>
            </div>
  
            <div className="flex mb-4">
              <div className="w-1/2 pr-2">
                <label htmlFor="region" className="block text-sm font-medium text-gray-700">Region</label>
                <input
                  type="text"
                  id="region"
                  name="region"
                  value={planDetails.region || ''}
                  readOnly
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-gray-100 rounded-md shadow-sm focus:outline-none sm:text-sm"
                />
              </div>
              <div className="w-1/2 pr-2">          
                <label htmlFor="chooseFile" className="block text-sm font-medium text-gray-700">Choose File</label>
                <input type="file" id="chooseFile" name="chooseFile" className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none sm:text-sm" />
              </div>
            </div>
          </>
        );
      }
    } 
     else if (mode === 'LOCAL') { // SINGLE upload type
      return (
        <>
          <div className="flex mb-4">
            <div className="w-1/2 pr-2">
              <label htmlFor="supplier" className="block text-sm font-medium text-gray-700">Supplier Name</label>
              <input
                type="number"
                id="supplier"
                name="supplier"
                value={planDetails.supplier || ''}
                readOnly
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-gray-100 rounded-md shadow-sm focus:outline-none sm:text-sm"
              />
            </div>
            
            <div className="w-1/2 pr-2">
              <label htmlFor="buyingcost" className="block text-sm font-medium text-gray-700">Buying Cost</label>
              <input
                type="text"
                id="buyingcost"
                name="buyingcost"
                value={planDetails.buyingcost || ''}
                readOnly
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-gray-100 rounded-md shadow-sm focus:outline-none sm:text-sm"
              />
            </div>
          </div>
  
          <div className="flex mb-4">
            <div className="w-1/2 pr-2">
              <label htmlFor="country" className="block text-sm font-medium text-gray-700">Country</label>
              <input
                type="text"
                id="country"
                name="country"
                value={planDetails.country || ''}
                readOnly
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-gray-100 rounded-md shadow-sm focus:outline-none sm:text-sm"
              />
            </div>
          </div>
  
          <div className="flex mb-4">
            <div className="w-1/2 pr-2">
              <label htmlFor="activationCode" className="block text-sm font-medium text-gray-700">Activation Code</label>
              <input
                type="text"
                id="activationCode"
                name="activationCode"
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none sm:text-sm"
              />
            </div>
            
            <div className="w-1/2 pr-2">
              <label htmlFor="simPin" className="block text-sm font-medium text-gray-700">Sim Pin</label>
              <input
                type="number"
                id="simPin"
                name="simPin"
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none sm:text-sm"
              />
            </div>
          </div>
  
          <div className="flex mb-4">
            <div className="w-1/2 pr-2">
              <label htmlFor="iccid" className="block text-sm font-medium text-gray-700">ICCID</label>
              <input
                type="text"
                id="iccid"
                name="iccid"
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none sm:text-sm"
              />
            </div>
            
            <div className="w-1/2 pr-2">
              <label htmlFor="serviceDeviceId" className="block text-sm font-medium text-gray-700">Service Device ID</label>
              <input
                type="text"
                id="serviceDeviceId"
                name="serviceDeviceId"
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none sm:text-sm"
              />
            </div>
          </div>
        </>
      );
    }
    else  { // SINGLE upload type
      return (
        <>
          <div className="flex mb-4">
            <div className="w-1/2 pr-2">
              <label htmlFor="supplier" className="block text-sm font-medium text-gray-700">Supplier Name</label>
              <input
                type="number"
                id="supplier"
                name="supplier"
                value={planDetails.supplier || ''}
                readOnly
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-gray-100 rounded-md shadow-sm focus:outline-none sm:text-sm"
              />
            </div>
            
            <div className="w-1/2 pr-2">
              <label htmlFor="buyingcost" className="block text-sm font-medium text-gray-700">Buying Cost</label>
              <input
                type="text"
                id="buyingcost"
                name="buyingcost"
                value={planDetails.buyingcost || ''}
                readOnly
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-gray-100 rounded-md shadow-sm focus:outline-none sm:text-sm"
              />
            </div>
          </div>
  
          <div className="flex mb-4">
            <div className="w-1/2 pr-2">
              <label htmlFor="region" className="block text-sm font-medium text-gray-700">Region</label>
              <input
                type="text"
                id="region"
                name="region"
                value={planDetails.region || ''}
                readOnly
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-gray-100 rounded-md shadow-sm focus:outline-none sm:text-sm"
              />
            </div>
          </div>
  
          <div className="flex mb-4">
            <div className="w-1/2 pr-2">
              <label htmlFor="activationCode" className="block text-sm font-medium text-gray-700">Activation Code</label>
              <input
                type="text"
                id="activationCode"
                name="activationCode"
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none sm:text-sm"
              />
            </div>
            
            <div className="w-1/2 pr-2">
              <label htmlFor="simPin" className="block text-sm font-medium text-gray-700">Sim Pin</label>
              <input
                type="number"
                id="simPin"
                name="simPin"
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none sm:text-sm"
              />
            </div>
          </div>
  
          <div className="flex mb-4">
            <div className="w-1/2 pr-2">
              <label htmlFor="iccid" className="block text-sm font-medium text-gray-700">ICCID</label>
              <input
                type="text"
                id="iccid"
                name="iccid"
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none sm:text-sm"
              />
            </div>
            
            <div className="w-1/2 pr-2">
              <label htmlFor="serviceDeviceId" className="block text-sm font-medium text-gray-700">Service Device ID</label>
              <input
                type="text"
                id="serviceDeviceId"
                name="serviceDeviceId"
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none sm:text-sm"
              />
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <div className="p-4">
      <div className="mb-4">
        <button
          className={`px-4 py-2 rounded-md ${uploadType === 'SINGLE' ? 'bg-indigo-500 text-white' : 'bg-gray-200 text-gray-700'}`}
          onClick={() => setUploadType('SINGLE')}
        >
          Single Inventory Upload
        </button>
        <button
          className={`ml-2 px-4 py-2 rounded-md ${uploadType === 'BULK' ? 'bg-indigo-500 text-white' : 'bg-gray-200 text-gray-700'}`}
          onClick={() => setUploadType('BULK')}
        >
          Bulk Inventory Upload
        </button>
      </div>
      <div className="mb-4">
        <button
          className={`px-4 py-2 rounded-md ${mode === 'LOCAL' ? 'bg-indigo-500 text-white' : 'bg-gray-200 text-gray-700'}`}
          onClick={() => {
            setMode('LOCAL');
            resetPlanDetails();
          }}
        >
          LOCAL
        </button>
        <button
          className={`ml-2 px-4 py-2 rounded-md ${mode === 'ROAMING' ? 'bg-indigo-500 text-white' : 'bg-gray-200 text-gray-700'}`}
          onClick={() => {
            setMode('ROAMING');
            resetPlanDetails();
          }}
        >
          ROAMING
        </button>
      </div>

      <form onSubmit={handleSubmit}>
        <label htmlFor="plan" className="block text-sm font-medium text-gray-700">Plan Name</label>
        <select
        type="text"
          id="plan"
          name="plan"
          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          value={selectedPlanId || ''}
          onChange={(e) => setSelectedPlanId(e.target.value)}
        >
          <option value="">Select a plan</option>
          {plans.map(plan => (
            <option key={plan.P_id} value={plan.P_id}>{plan.planename}</option>
          ))}
        </select>

        <div className="mt-4">
          {renderFields()}
        </div>

        <button type="submit" className="mt-4 px-4 py-2 bg-indigo-500 text-white rounded-md shadow-sm hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
          Submit
        </button>
      </form>
    </div>
  );
};

export default UploadInventory;
