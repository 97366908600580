import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { SiShopware } from 'react-icons/si';
import { MdOutlineCancel, MdKeyboardArrowDown } from 'react-icons/md';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { AiOutlineStock, AiOutlineShoppingCart } from 'react-icons/ai';
import { IoMdContacts } from 'react-icons/io';
import { RiContactsLine } from 'react-icons/ri';
import Airhub from '../assests/Airhub_logo.png';

import { links } from '../data/dummy';
import { useStateContext } from '../contexts/ContextProvider';

const Sidebar = () => {
  const { currentColor, activeMenu, setActiveMenu, screenSize } = useStateContext();
  const [showDropdown, setShowDropdown] = useState(false);
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    const username = sessionStorage.getItem('username');
    const moduleIds = sessionStorage.getItem('moduleIds');
  
    if (moduleIds) {
      const moduleIdArray = moduleIds.split(',').map(id => parseInt(id.trim()));
      let tempMenuItems = [];
      if (tempMenuItems.length === 0) {
        tempMenuItems.push({
          title: 'Dashboard',
          links: [
            { name: 'Dashboard', icon: <AiOutlineShoppingCart /> },
         
          ],
        });
      }
      if (moduleIdArray.includes(1) || moduleIdArray.includes(2) || moduleIdArray.includes(3)) {
        tempMenuItems.push({
          title: 'Plans',
          links: [
            // { id: 1, name: 'Bindregion', icon: <AiOutlineStock /> },
            { id: 2, name: 'Plans', icon: <AiOutlineStock /> },
           ],
        });
      }
  
      if (moduleIdArray.includes(4) || moduleIdArray.includes(5) || moduleIdArray.includes(6)) {
        tempMenuItems.push({
          title: 'Inventory',
          links: [
            { id: 1, name: 'QuickTool', icon: <AiOutlineStock /> },
            { id: 2, name: 'UploadInventory', icon: <AiOutlineStock /> },
          ],
        });
      }

      if (moduleIdArray.includes(7) || moduleIdArray.includes(21) || moduleIdArray.includes(22)) {
        tempMenuItems.push({
          title: 'Billing',
          links: [
            { id: 1, name: 'CreateCustomer', icon: <AiOutlineStock /> },
            { id: 2, name: 'CreateOfflinePlans', icon: <AiOutlineStock /> },
         


          ],
        });
      }
  
      
      if (moduleIdArray.includes(11) || moduleIdArray.includes(2) || moduleIdArray.includes(32)) {
        tempMenuItems.push({
          title: 'Create User',
          links: [
     
            { id: 1, name: 'GetAllUsers', icon: <AiOutlineStock /> },
            { id: 2, name: 'Reguser', icon: <AiOutlineStock /> },


          ],
        });
      }
      if (tempMenuItems.length === 0) {
        tempMenuItems.push({
          title: 'Pages',
          links: [
            { name: 'Country', icon: <AiOutlineShoppingCart /> },
            { name: 'Data Sync', icon: <IoMdContacts />, hasDropdown: true, dropdownLinks: ['deepak', 'sameer', 'aby', 'rahul'] },
            { name: 'Plan', icon: <RiContactsLine /> },
            {
              name: 'Plan',
              icon: <IoMdContacts />,
              hasDropdown: true,
              dropdownLinks: ['Create Plan', 'View Plans', 'Esim Pop ', 'Rechargable Topup', 'Plan Order set'],
            },
          ],
        });
      }
  
      setMenuItems(tempMenuItems);
    }
  }, []);
  

  const handleCloseSideBar = () => {
    if (activeMenu !== undefined && screenSize <= 900) {
      setActiveMenu(false);
    }
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const activeLink = 'flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-white text-md m-2';
  const normalLink = 'flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2';

  return (
    <div className="ml-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10">
      {activeMenu && (
        <>
          <div className="flex justify-between items-center">
            <Link to="/" onClick={handleCloseSideBar} className="items-center gap-3 ml-3 mt-4 flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900">
              <span>
                <div className="flex justify-center mb-5 ml-6">
                  <img src={Airhub} alt="ScannerGo" className="w-20" />
                </div>
              </span>
            </Link>
            <TooltipComponent content="Menu" position="BottomCenter">
              <button
                type="button"
                onClick={() => setActiveMenu(!activeMenu)}
                style={{ color: currentColor }}
                className="text-xl rounded-full p-3 hover:bg-light-gray mt-4 block md:hidden"
              >
                <MdOutlineCancel />
              </button>
            </TooltipComponent>
          </div>
          <div className="mt-10 ">
            {menuItems.map((item) => (
              <div key={item.title}>
                <p className="text-gray-400 dark:text-gray-400 m-3 mt-4 uppercase">
                  {item.title}
                </p>
                {item.links.map((link) => (
                  <div key={link.name}>
                    {link.hasDropdown ? (
                      <>
                        <div className="flex items-center">
                          <NavLink
                            to={`/${link.name}`}
                            onClick={toggleDropdown}
                            style={({ isActive }) => ({
                              backgroundColor: isActive ? currentColor : '',
                            })}
                            className={({ isActive }) => (isActive ? activeLink : normalLink)}
                          >
                            {link.icon}
                            <span className="capitalize">{link.name}</span>
                          </NavLink>
                          <MdKeyboardArrowDown size={30} onClick={toggleDropdown} className="text-gray-500 ml-2 cursor-pointer" />
                        </div>
                        {showDropdown && (
                          <div className="pl-8 mt-2">
                            {link.dropdownLinks.map((dropdownItem, index) => (
                              <NavLink
                                key={index}
                                to={`/${dropdownItem}`}
                                onClick={handleCloseSideBar}
                                style={({ isActive }) => ({
                                  backgroundColor: isActive ? currentColor : '',
                                })}
                                className={({ isActive }) => (isActive ? activeLink : normalLink)}
                              >
                                {dropdownItem}
                              </NavLink>
                            ))}
                          </div>
                        )}
                      </>
                    ) : (
                      <NavLink
                        to={`/${link.name}`}
                        onClick={handleCloseSideBar}
                        style={({ isActive }) => ({
                          backgroundColor: isActive ? currentColor : '',
                        })}
                        className={({ isActive }) => (isActive ? activeLink : normalLink)}
                      >
                        {link.icon}
                        <span className="capitalize">{link.name}</span>
                      </NavLink>
                    )}
                  </div>
                ))}
              </div>
            ))}
          </div>  
        </>
      )}
    </div>
  );
};

export default Sidebar;