import React, { useState } from 'react';
import axios from 'axios';

const token = sessionStorage.getItem("token");
console.log(token);
const userID = sessionStorage.getItem('userID');

const CreateCustomer = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        userName: '',
        cNumber: '',
        cAddress: '',
        cSource: '',
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
            flag: '1',
            Uid: userID,
            Username: formData.userName,
            firstName: formData.firstName,
            lastName: formData.lastName,
            Contact: formData.cNumber,
            Address: formData.cAddress,
            source: formData.cSource,
            CustomerType: 'Offline',
            Password: '',
            Refer: '',
            IP: ''
        };

        try {
            const response = await axios.post('https://localhost:7049/api/Billing/CreateOfflineCustomer', data, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            if (!response.ok) {
                alert('Create Customer API did not work.');
            } else {
                alert(response.data);
                window.location.reload();
            }
        } catch (error) {
            alert('Error occurred while creating customer.');
        }
    };

    return (
        <div className="max-w-md mx-auto mt-8 shadow-md p-6 rounded-md bg-white">
          <h2 className="text-lg font-semibold mb-4">Create New Customer</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">First Name</label>
              <input type="text" id="firstName" name="firstName" value={formData.firstName} onChange={handleChange} className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
            </div>
      
            <div className="mb-4">
              <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">Last Name</label>
              <input type="text" id="lastName" name="lastName" value={formData.lastName} onChange={handleChange} className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
            </div>
      
            <div className="mb-4">
              <label htmlFor="userName" className="block text-sm font-medium text-gray-700">Username</label>
              <input type="text" id="userName" name="userName" placeholder="Enter your Email" value={formData.userName} onChange={handleChange} className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
            </div>
      
            <div className="mb-4">
              <label htmlFor="cNumber" className="block text-sm font-medium text-gray-700">Contact</label>
              <input type="number" id="cNumber" name="cNumber" value={formData.cNumber} onChange={handleChange} className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
            </div>
      
            <div className="mb-4">
              <label htmlFor="cAddress" className="block text-sm font-medium text-gray-700">Address</label>
              <input type="text" id="cAddress" name="cAddress" value={formData.cAddress} onChange={handleChange} className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
            </div>
      
            <div className="mb-4">
              <label htmlFor="cSource" className="block text-sm font-medium text-gray-700">Source</label>
              <select id="cSource" name="cSource" value={formData.cSource} onChange={handleChange} className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                <option value="">Select</option>
                <option value="SHP">SHP</option>
                <option value="KLK">KLK</option>
                <option value="LZD">LZD</option>
                <option value="TVLKA">TVLKA</option>
                <option value="KKD">KKD</option>
                <option value="AMZUK">AMZUK</option>
                <option value="AMZUS">AMZUS</option>
                <option value="RKTN">RKTN</option>
                <option value="NVR">NVR</option>
                <option value="TKT">TKT</option>
                <option value="WebMis">WEB Missing</option>
                <option value="E_BY">E_BY</option>
                <option value="AIRHUB UK">AIRHUB UK</option>
                <option value="TEST">TEST</option>
              </select>
            </div>
      
            <div className="mt-4 text-right">
              <button type="submit" className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                Save
              </button>
            </div>
          </form>
        </div>
    );      
};

export default CreateCustomer;
