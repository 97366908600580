import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';

const Bindregion = () => {
  const [regions, setRegions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = sessionStorage.getItem('token');
        if (!token) {
          setError('Token is missing.');
          setLoading(false);
          return;
        }
        const response = await axios.get(
          'https://i47v6yw3lg6zz3tvjpzvlhwe2a0xxaef.lambda-url.ap-south-1.on.aws/api/Country/BindRegion?flag=1',
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );

        const jsonData = JSON.parse(response.data.regions[0].jsonData);
        setRegions(jsonData.Table);
        setLoading(false);
      } catch (error) {
        setError('Failed to fetch regions.');
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  const customStyles = {
    headCells: {
      style: {
        fontWeight: 'bold',
        backgroundColor:'#fb9678',
        fontSize: '0.9rem', // Increase the font size

      },
    },
  };
  if (loading) return (
    <div className="flex justify-center items-center h-screen">
      <div className="text-2xl">Loading...</div>
    </div>
  );
  
  if (error) return <div>Error: {error}</div>;

  const columns = regions.length > 0 ? Object.keys(regions[0]).map(key => ({
    name: key,
    selector: row => row[key],
    sortable: true,
  })) : [];

  return (
    <div className="flex justify-center mt-4">
    <div className="overflow-x-auto w-full">
      <br />
      <DataTable
        columns={columns}
        data={regions}
        pagination
        highlightOnHover
        striped
        className="bg-white"
        customStyles={customStyles}
      />
    </div>
  </div>
  );
};

export default Bindregion;
