import React, { useState, useEffect } from 'react';

const RegisterForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    password: '',
    confirmPassword: '',
    emailID: '',
    role: '',
    twoFA: '',
    CreatedBy: 1,
  });

  useEffect(() => {
    // Check if user is authenticated (replace with your actual authentication logic)
    const isAuthenticated = sessionStorage.getItem('token') !== null;
    
    if (!isAuthenticated) {
      // Redirect to login or handle unauthorized access
      console.log('User is not authenticated');
    } else {
      // Fetch user details or session data (replace with your actual session management logic)
      const userId = sessionStorage.getItem('userId');
      setFormData((prevFormData) => ({
        ...prevFormData,
        CreatedBy: userId ? parseInt(userId, 10) : 1,
      }));
    }
  }, []);

  const handleChange = (e) => {
    const { name, value, options } = e.target;

    if (name === 'moduleIds') {
      const selectedOptions = Array.from(options).filter(option => option.selected).map(option => option.value);
      setFormData({
        ...formData,
        [name]: selectedOptions.join(','), // Join selected values with a comma
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form data here before sending to API
    if (validateFormData()) {
      // Show alert with form data before sending to API
      alert(JSON.stringify(formData, null, 2));

      try {
        // Replace with your actual API endpoint
        // const apiUrl = 'https://i47v6yw3lg6zz3tvjpzvlhwe2a0xxaef.lambda-url.ap-south-1.on.aws/api/User/RegisterUser';
        const apiUrl = 'https://localhost:7049/api/User/RegisterUser';

        // Fetch token from local storage
        const token = sessionStorage.getItem('token');
        const role =sessionStorage.getItem('userID')
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(formData),
        });

        if (!response.ok) {
          throw new Error('Failed to register user');
        }

        const data = await response.json();
        console.log('API response:', data);

        // Optionally, reset form after successful submission
        setFormData({
          firstName: '',
          lastName: '',
          password: '',
          confirmPassword: '',
          emailID: '',
          role: '',
          twoFA: '',
          createdByUserId: role, // Reset createdByUserId
        });

        alert('User registered successfully!');
      } catch (error) {
        console.error('API error:', error);
        alert('Failed to register user. Please try again.');
      }
    }
  };

  const validateFormData = () => {
    // Implement your validation logic here
    // Example: check if required fields are filled, passwords match, etc.
    return true; // For demonstration purposes, always return true
  };

  return (
    <div className="max-w-md mx-auto mt-8 shadow-md p-6 rounded-md bg-white">
      <h2 className="text-lg font-semibold mb-4">Register User</h2>
      <form onSubmit={handleSubmit}>

        <div className="mb-4">
          <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">First Name</label>
          <input type="text" id="firstName" name="firstName" value={formData.firstName} onChange={handleChange} className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
        </div>

        <div className="mb-4">
          <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">Last Name</label>
          <input type="text" id="lastName" name="lastName" value={formData.lastName} onChange={handleChange} className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
        </div>

        <div className="mb-4">
          <label htmlFor="emailID" className="block text-sm font-medium text-gray-700">Email ID</label>
          <input type="email" id="emailID" name="emailID" value={formData.emailID} onChange={handleChange} className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
        </div>

        <div className="mb-4">
          <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
          <input type="password" id="password" name="password" value={formData.password} onChange={handleChange} className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
        </div>

        <div className="mb-4">
          <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">Confirm Password</label>
          <input type="password" id="confirmPassword" name="confirmPassword" value={formData.confirmPassword} onChange={handleChange} className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
        </div>

        <div className="mb-4">
          <label htmlFor="role" className="block text-sm font-medium text-gray-700">Role</label>
          <select id="role" name="role" value={formData.role} onChange={handleChange} className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
            <option value="">Select Role</option>
            <option value="1">Super Admin</option>
            <option value="2">Admin</option>
            <option value="3">Support</option>
            <option value="4">User</option>
            <option value="5">Partner</option>
            <option value="6">Operation</option>
            <option value="7">Marketing</option>
            <option value="8">Finance</option>
            <option value="9">Developer</option>

          </select>
        </div>

        <div className="mb-4">
          <label htmlFor="twoFA" className="block text-sm font-medium text-gray-700">Two Factor Authentication</label>
          <select id="twoFA" name="twoFA" value={formData.twoFA} onChange={handleChange} className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
            <option value="">Select TwoFA</option>
            <option value="1">Enable</option>
            <option value="2">Disable</option>
          </select>
        </div>
        
        <div className="mt-4">
          <button type="submit" className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
            Register
          </button>
        </div>
      </form>
    </div>
  );
};

export default RegisterForm;
