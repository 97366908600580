import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";

const BindPlanName = () => {
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const itemsPerPage = 300; // Number of items per page

  useEffect(() => {
    let isMounted = true; // Flag to track component mounting
    const fetchPlans = async () => {
      const token = sessionStorage.getItem("token");
      if (!token) {
        setError("No token found");
        setLoading(false);
        return;
      }
      try {
        const response = await fetch(
          "https://i47v6yw3lg6zz3tvjpzvlhwe2a0xxaef.lambda-url.ap-south-1.on.aws/api/Plan/BindPlanName?flag=1",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        const jsonData = JSON.parse(data.data[0].jsonData);
        const planNames = jsonData.Table; // Ensure planNames is an array
        if (!Array.isArray(planNames)) {
          throw new Error("Invalid data format: planNames is not an array");
        }
        if (isMounted) {
          setPlans(planNames);
          setPageCount(Math.ceil(planNames.length / itemsPerPage));
          setLoading(false);
        }
      } catch (error) {
        if (isMounted) {
          setError(error.message);
          setLoading(false);
        }
      }
    };

    fetchPlans();

    // Cleanup function to execute when the component unmounts
    return () => {
      isMounted = false; // Update flag to indicate component unmount
    };
  }, []);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const displayPlans = plans.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  // Pagination UI
  const visiblePages = [];
  const maxVisiblePages = 5;
  let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
  let endPage = Math.min(pageCount, startPage + maxVisiblePages - 1);

  if (endPage - startPage < maxVisiblePages - 1) {
    startPage = Math.max(1, endPage - maxVisiblePages + 1);
  }

  for (let i = startPage; i <= endPage; i++) {
    visiblePages.push(i);
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Plan Names</h1>
      {loading ? (
        <div className="text-center text-xl">Loading...</div>
      ) : error ? (
        <div className="text-red-500">Error: {error}</div>
      ) : (
        <>
          <table className="min-w-full bg-white shadow-md rounded my-6">
            <thead>
              <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                <th className="py-3 px-6 text-left">ID</th>
                <th className="py-3 px-6 text-left">Plan Name</th>
                <th className="py-3 px-6 text-left">P_id</th>
              </tr>
            </thead>
            <tbody className="text-gray-600 text-sm font-light">
              {displayPlans.map((plan, index) => (
                <tr key={index} className="border-b border-gray-200 hover:bg-gray-100">
                  <td className="py-3 px-6 text-left whitespace-nowrap">{plan.ID}</td>
                  <td className="py-3 px-6 text-left">{plan.planename}</td>
                  <td className="py-3 px-6 text-left">{plan.P_id}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {/* Pagination */}
          <nav className="flex justify-between items-center mt-4">
            <div className="flex items-center">
              <button
                className={`relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md ${
                  currentPage === 0 ? 'text-gray-400 bg-gray-100 cursor-not-allowed' : 'text-gray-700 bg-white hover:bg-gray-50'
                }`}
                onClick={() => setCurrentPage(currentPage - 1)}
                disabled={currentPage === 0}
              >
                Previous
              </button>
              {visiblePages.map((page) => (
                <button
                  key={page}
                  className={`relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md ${
                    currentPage === page - 1 ? 'text-white bg-indigo-600 hover:bg-indigo-700' : 'text-gray-700 bg-white hover:bg-gray-50'
                  }`}
                  onClick={() => setCurrentPage(page - 1)}
                >
                  {page}
                </button>
              ))}
              <button
                className={`relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md ${
                  currentPage === pageCount - 1 ? 'text-gray-400 bg-gray-100 cursor-not-allowed' : 'text-gray-700 bg-white hover:bg-gray-50'
                }`}
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={currentPage === pageCount - 1}
              >
                Next
              </button>
            </div>
            <div className="flex items-center space-x-2">
              <span className="text-sm text-gray-500">Go to page:</span>
              <input
                type="number"
                className="w-16 px-2 py-1 border border-gray-300 rounded-md text-sm focus:outline-none focus:ring focus:ring-indigo-200"
                min="1"
                max={pageCount}
                defaultValue={currentPage + 1}
                onChange={(e) => {
                  let value = e.target.value;
                  if (value === '') value = 1;
                  value = parseInt(value);
                  if (!isNaN(value) && value >= 1 && value <= pageCount) {
                    setCurrentPage(value - 1);
                  }
                }}
              />
            </div>
          </nav>
        </>
      )}
    </div>
  );
};

export default BindPlanName;
