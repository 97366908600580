import React, { useEffect, useState } from 'react';

const UserTable = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUsers = async () => {
      // Retrieve token from session storage
      const token = sessionStorage.getItem('token');

      if (!token) {
        console.error('No token found');
        return;
      }

      try {
        const response = await fetch('https://i47v6yw3lg6zz3tvjpzvlhwe2a0xxaef.lambda-url.ap-south-1.on.aws/api/User/GetAllUsers', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Add token to request headers
          }
        });

        const result = await response.json();

        if (result.isSuccess && result.data.length > 0) {
          // Parse the JSON string inside jsonData
          const parsedData = JSON.parse(result.data[0].jsonData).Table;
          setUsers(parsedData);
        } else {
          console.error('Failed to fetch users');
        }
      } catch (error) {
        console.error('Error fetching users:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white border border-gray-200">
        <thead>
          <tr>
            <th className="px-4 py-2">First Name</th>
            <th className="px-4 py-2">Last Name</th>
            <th className="px-4 py-2">User Name</th>
            <th className="px-4 py-2">Email</th>
            <th className="px-4 py-2">Role Type</th>
            <th className="px-4 py-2">Status</th>
            {/* Add more table headers as needed */}
          </tr>
        </thead>
        <tbody>
          {users.map((user, index) => (
            <tr key={index}>
              <td className="border px-4 py-2">{user.FirstName}</td>
              <td className="border px-4 py-2">{user.LastName}</td>
              <td className="border px-4 py-2">{user.UserName}</td>
              <td className="border px-4 py-2">{user.emailid}</td>
              <td className="border px-4 py-2">{user.RoleType}</td>
              <td className="border px-4 py-2">{user.Status}</td>
              {/* Add more table data as needed */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserTable;
