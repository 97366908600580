import React from 'react';
import { MdOutlineCancel } from 'react-icons/md';
import { useNavigate } from 'react-router-dom'; // Assuming you're using React Router
import { Button } from '.';
import { useStateContext } from '../contexts/ContextProvider';
import avatar from '../data/avatar.jpg';

const UserProfile = () => {
  const navigate = useNavigate();
  const { currentColor } = useStateContext();

  // Fetching user data from session storage
  const username = sessionStorage.getItem('username') || 'Unknown User';
  const role = sessionStorage.getItem('role') || 'Unknown Role';
  const email = sessionStorage.getItem('email') || 'No Email';

  const handleLogout = () => {
    sessionStorage.clear();
    navigate('/');
  };

  return (
    <div className="nav-item absolute right-1 top-16 bg-white dark:bg-[#42464D] p-8 rounded-lg w-96">
      <div className="flex justify-between items-center">
        <p className="font-semibold text-lg dark:text-gray-200">User Profile</p>
        <Button
          icon={<MdOutlineCancel />}
          color="rgb(153, 171, 180)"
          bgHoverColor="light-gray"
          size="2xl"
          borderRadius="50%"
          onClick={handleLogout} // Call handleLogout function on button click
        />
      </div>
      <div className="flex gap-5 items-center mt-6 border-color border-b-1 pb-6">
        <img
          className="rounded-full h-24 w-24"
          src={avatar}
          alt="user-profile"
        />
        <div>
          <p className="font-semibold text-xl dark:text-gray-200">{username}</p>
          <p className="text-gray-500 text-sm dark:text-gray-400">{role}</p>
          <p className="text-gray-500 text-sm font-semibold dark:text-gray-400">{email}</p>
        </div>
      </div>

      <div className="mt-5 text-center rounded-lg  text-white" style={{ backgroundColor: currentColor }}>
  <h1 style={{ fontSize: '1.5rem', margin: '0', padding: '10px', cursor: 'pointer' }} onClick={handleLogout}>Logout</h1>
</div>

    </div>
  );
};

export default UserProfile;
